import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Four0Four from "./404";
import URI from "urijs";
import { useQuery } from "@apollo/client";
import useResponsive from "../hooks/useResponsive";
import Meta from "../components/meta";
import { GET_ARTISTS } from "../graphql/queries/inventory";
import useTranslation from "../hooks/useTranslation";

const Artists = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const location = useLocation();
  const search = new URI(location.pathname + location.search).search(true);
  const letter = search.letter;

  const { data, loading } = useQuery(GET_ARTISTS);

  useEffect(() => {
    if (letter) {
      const element = document.getElementById(`letter-${letter}`);
      if (element) element.scrollIntoView();
    }
  }, [letter]);

  if (loading) return null;

  const artists = data && data.artists;

  if (loading) return null;
  else if (!data?.artists) return <Four0Four />;

  const letters = [];
  for (let i = 0; i < 26; i++) {
    const character = String.fromCharCode(97 + i);
    letters.push({
      letter: character,
      entries: artists ? artists.filter(a => a.name.toLowerCase().charAt(0) === character) : []
    });
  }

  return (
    <div id="artists" className={isMobile ? "mobile" : ""}>
      <Meta title="Artists" index={false} />
      <div className="header">
        <h1>{t("Artists")}</h1>
        <div className="abecedaire">
          {letters.map(l => (
            <Link
              key={l.letter}
              className={`${l.letter === letter ? "active" : ""}`}
              to={new URI(location.pathname).setSearch({ letter: l.letter }).toString()}
            >
              {l.letter}
            </Link>
          ))}
        </div>
      </div>
      <div className="content">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 3, 750: 5, 900: 8 }}>
          <Masonry gutter="100">
            {letters
              .filter(l => l.entries.length)
              .map(l => (
                <div key={l.letter} className={`entry ${l.letter === letter ? "active" : ""}`} id={`letter-${l.letter}`}>
                  <h3>{l.letter}</h3>
                  {l.entries.map((e, i) => (
                    <p key={e.name + i}>
                      <Link className="name" to={`/catalogue?artists=${e.id}&stock=all`}>
                        {e.name}
                      </Link>
                    </p>
                  ))}
                </div>
              ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
};

const Routes = [
  {
    path: "/catalogue/artists",
    element: <Artists />
  }
];

export default Artists;
export { Routes };
