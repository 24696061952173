import React from "react";
import Four0Four from "./404";
import Block from "../components/blocks";
import Meta from "../components/meta";
import { Store } from "../store";
import useResponsive from "../hooks/useResponsive";
import { useQuery } from "@apollo/client";
import { findUrlForMediaArray } from "../utils";
import { GET_ARTICLE } from "../graphql/queries/article";
import { ArticleLink, ConfigEshopPreferencesHomepage, Theme } from "../__generated__/graphql";

const Article = ({ handle, isHomepage }: { handle: string; isHomepage?: boolean }) => {
  const theme = Store.useState(s => s.theme) as Theme;

  const settings = theme.settings;
  const { isMobile } = useResponsive({ maxWidth: settings?.mobileBreakpoint || 920 });

  const { data, loading } = useQuery(GET_ARTICLE, { variables: { handle } });

  if (loading || (data && data.article === undefined)) return null;
  else if (!data?.article) return <Four0Four />;

  const article = data.article;

  return (
    <div id="article" className={`${isHomepage ? "homepage" : ""} ${article.handle.replace("/", "")}`}>
      <Meta
        path={article.handle}
        description={isHomepage ? null : article.seo?.description || article.title}
        index={!!article.seo?.index}
        title={isHomepage ? null : article.seo?.title || article.title}
        updated={article.published?.date}
        image={article.thumbnail ? findUrlForMediaArray(article.thumbnail) : null}
      />
      {data.article.content.rows.map(r => (
        <div
          key={r.id}
          className={`row ${isMobile ? "mobile" : ""} ${r.layout} ${r.options && r.options.fullWidth ? "fullWidth " : ""} ${
            r.options && r.options.noPadding ? "noPadding " : ""
          }`}
        >
          {r.columns.map(c => (
            <div key={c.id} className="column">
              {c.blocks.map(b => (
                <Block key={b.id} block={b} />
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Routes = ({ articles, homepage }: { articles: ArticleLink[]; homepage?: ConfigEshopPreferencesHomepage | null }) => {
  const routes = articles.map(a => ({ path: a.handle, element: <Article handle={a.handle} /> }));
  if (homepage?.type === "article") routes.push({ path: "/", element: <Article handle={homepage.handle as string} isHomepage={true} /> });

  return routes;
};

export default Article;
export { Routes };
