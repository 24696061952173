import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation, Location } from "react-router-dom";
import Menu from "../components/menu";
import { useTranslation } from "react-i18next";
import { Store } from "../store";
import useModal from "../hooks/useModal";
import Home from "./home";
import useResponsive from "../hooks/useResponsive";
import { getModalStyles } from "../utils";
import { Checkout, Config, Theme } from "../__generated__/graphql";

const Header = () => {
  const { config, checkout } = Store.useState(s => s) as { config: Config; checkout: Checkout };
  const location = useLocation();
  const { Modal, useModalComponent } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const theme = Store.useState(s => s.theme) as Theme;
  const { isMobile } = useResponsive({ maxWidth: theme?.settings?.mobileBreakpoint || 920 });
  const [isSearching, setIsSearching] = useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const navigate = useNavigate();

  const icon = config.modules.find(m => m.id === "designs")?.data.squareSVG || config.modules.find(m => m.id === "designs")?.data.logoImage;

  useEffect(() => {
    setIsOpen(false);
    setIsSearching(false);
  }, [location.pathname]);

  useEffect(() => {
    setIsSearching(false);
    if (location.hash && !isOpen) {
      setIsOpen(true);
    }
  }, [location.hash]);

  const settings = theme?.settings;
  const backgroundColor = settings?.header?.backgroundColor;

  useEffect(() => {
    if (isOpen) showModal();
    else hideModal();
  }, [isOpen]);

  const styles = getModalStyles(theme);
  if (isMobile && styles.content) {
    styles.content.backgroundColor = "#fff";
    styles.content.width = "95vw";
    styles.content.height = "80vh";
    styles.content.padding = "0px";
    styles.content.borderRadius = "20px";
  } else if (styles.content) {
    styles.content.backgroundColor = "#fff";
    styles.content.width = "70vw";
    styles.content.height = "70vh";
    styles.content.padding = "0px";
    styles.content.borderRadius = "20px";
  }

  const [showModal, hideModal] = useModalComponent(
    () => (
      <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={styles}>
        <Home isMobile={isMobile} exit={() => setIsOpen(false)} />
      </Modal>
    ),
    [isOpen, isMobile, setIsOpen]
  );

  useEffect(() => {
    Store.update(s => {
      s.setHomeOpen = state => setIsOpen(state);
    });
  }, []);

  const handleToggleMobileMenu = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
    setIsSearching(false);
  };

  const handleToggleSearch = () => {
    setMobileMenuIsOpen(false);
    setIsSearching(!isSearching);
  };

  const hasCheckoutItem = checkout.items.length > 0;
  const layout = settings?.header?.layout?.id || "centered";

  const style = { backgroundColor: backgroundColor ? `var(--${backgroundColor.id})` : "none" };
  const logoWidth = (isMobile ? settings?.header?.logoWidth?.mobile : settings?.header?.logoWidth?.desktop) || 90;

  const BasketComponent = () => (
    <button
      style={{ display: "flex", gap: "5px", alignItems: "baseline", flexDirection: "row" }}
      onClick={() => navigate("/home?prev=" + location.pathname)}
    >
      <i className={`cg-icon-basket-${hasCheckoutItem ? "selected" : "cart"}`} />{" "}
      {checkout.items.length ? <span className="quantity"> {checkout.items.length}</span> : ""}
    </button>
  );

  if (isMobile)
    return (
      <div
        id="header"
        className={`${settings?.header?.layout?.id || "centered"} ${settings?.header?.sticky ? "sticky" : ""} mobile`}
        style={style}
      >
        {layout === "leftAligned" ? (
          <div className={"content"}>
            <div className="logo">
              <Link to="/">
                {icon ? <img style={{ width: logoWidth, height: "auto" }} alt="Logo" src={icon} /> : <h2>{config.shopName}</h2>}
              </Link>
            </div>
            <div className="profileAndBasket">
              <button onClick={handleToggleSearch}>
                <i className="cg-icon-search" />
              </button>
              <BasketComponent />
              <button type="button" className="burger" onClick={handleToggleMobileMenu}>
                <i className={mobileMenuIsOpen ? "cg-icon-close" : "cg-icon-burger"} />
              </button>
            </div>
          </div>
        ) : (
          <div className={"content"}>
            <button type="button" className="burger" onClick={handleToggleMobileMenu}>
              <i className={mobileMenuIsOpen ? "cg-icon-close" : "cg-icon-burger"} />
            </button>
            <div className="logo">
              <Link to="/">{icon ? <img style={{ width: logoWidth, height: "auto" }} alt="Logo" src={icon} /> : <span />}</Link>
            </div>
            <div className="profileAndBasket">
              <button onClick={handleToggleSearch}>
                <i className="cg-icon-search" />
              </button>
              <BasketComponent />
            </div>
          </div>
        )}
        <Search isMobile handleToggle={handleToggleSearch} isSearching={isSearching} setIsSearching={setIsSearching} />
        <MobileMenu theme={theme} isOpen={mobileMenuIsOpen} setIsOpen={setMobileMenuIsOpen} location={location} />
      </div>
    );

  return (
    <div id="header" className={`${settings?.header?.layout?.id || "centered"} ${settings?.header?.sticky ? "sticky" : ""}`} style={style}>
      <div className={"content"}>
        <Menu location="header" />
        <div className="logo">
          <Link to="/">
            {icon ? <img style={{ width: logoWidth, height: "auto" }} src={icon} alt="Logo" /> : <h2>{config.shopName}</h2>}
          </Link>
        </div>
        <div className="profileAndBasket">
          <button onClick={handleToggleSearch}>
            <i className="cg-icon-search" />
          </button>
          <button onClick={() => setIsOpen(true)}>
            <i className={`cg-icon-basket-${hasCheckoutItem ? "selected" : "cart"}`} />
            {checkout.items.length ? <span className="quantity"> {checkout.items.length}</span> : ""}
          </button>
        </div>
      </div>
      <Search handleToggle={handleToggleSearch} isSearching={isSearching} setIsSearching={setIsSearching} />
    </div>
  );
};

const MobileMenu = ({
  theme,
  isOpen,
  setIsOpen,
  location
}: {
  theme: Theme;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  location: Location;
}) => {
  const targetRef = React.createRef<any>();
  const settings = theme?.settings;

  // useEffect(() => {
  //   if (targetRef.current) {
  //     if (isOpen) disableBodyScroll(targetRef.current);
  //     else enableBodyScroll(targetRef.current);
  //   }
  //   return () => clearAllBodyScrollLocks();
  // }, [targetRef]);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div
      ref={targetRef}
      id="mobileMenu"
      className={isOpen ? "open" : "closed"}
      style={{ backgroundColor: `var(--${settings?.backgroundColor?.id})` }}
    >
      <div className="content">
        <Menu location="header" />
        <Menu location="navbar" />
      </div>
    </div>
  );
};

const Search = ({
  isMobile,
  handleToggle,
  isSearching,
  setIsSearching
}: {
  isMobile?: boolean;
  handleToggle: () => void;
  isSearching: boolean;
  setIsSearching: (state: boolean) => void;
}) => {
  const [term, setTerm] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchInputRef = useRef<any>();

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (term.length < 2) return;
    setIsSearching(false);
    setTerm("");
    navigate(`/search?term=${term}`);
  };

  window.addEventListener("keydown", e => {
    if ((e.ctrlKey || e.metaKey) && e.code === "KeyF") {
      e.preventDefault();
      if (!isSearching && !isMobile) {
        setIsSearching(true);
      }
    }
  });

  useEffect(() => {
    if (isSearching) searchInputRef.current.focus();
    else searchInputRef.current.blur();
  }, [isSearching]);

  return (
    <div id="search" className={isSearching ? "open" : ""}>
      <form onSubmit={handleSearch} action="/search" method="POST">
        <div className="left">
          <input
            required
            type="text"
            ref={searchInputRef}
            autoCapitalize="none"
            autoFocus={true}
            placeholder={t("searchPlaceholder") as string}
            autoComplete="off"
            value={term}
            onChange={e => setTerm(e.target.value)}
            name="term"
          />
        </div>
        <button type="button" onClick={handleToggle}>
          {" "}
          <i className="cg-icon-close" />
        </button>
      </form>
    </div>
  );
};

export default Header;
