import { gql } from "../../__generated__/gql";

gql(`
  fragment OrderFields on Order {
    _id
    id
    origin
    status
    created
    paymentUrl
    buyer {
      name
      email
      firstName
      lastName
    }
    messages {
      content
      subject
      created
      fromBuyer
      attachments {
        _id
        uri
        title
      }
      sender {
        name
        alias
      }
    }
    billing {
      status
      invoice {
        date
        pdf
        id
      }
      paymentMethods {
        origin
        amount
      }
    }
    shipping {
      tracking
      shopCollection
      address {
        alpha2
      }
    }
    items {
      ...CheckoutLineItemFields
    }
    totals {
      subtotal
      subtotalBeforeTaxes
      shipping
      grand
      discount
      itemQuantity
      taxes {
        name
        amount
      }
    }
  }
`);

export const POST_ORDER_MESSAGE = gql(`
  mutation orderAddMessage($id: String!, $message: String!) {
    orderAddMessage: orderAddMessage(id: $id, message: $message) {
      ...OrderFields
    }
}`);

export const GET_ORDERS = gql(`
  query orders{
    orders {
      ...OrderFields
    }
}`);

export const GET_ORDER = gql(`
query order($id: String!) {
  order(id: $id) {
    ...OrderFields
  }
}`);
