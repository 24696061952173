import React, { useState } from "react";
import Loader from "../components/loader";
import { useLoaderData } from "react-router-dom";
import { Tile } from "../components/views";
import URI from "urijs";
import useTranslation from "../hooks/useTranslation";
import { GET_SEARCH_ITEM } from "../graphql/queries/inventory";
import { useQuery } from "@apollo/client";

const Search = () => {
  const [stocked, setStocked] = useState<boolean>(false);
  const {
    data: { term }
  } = useLoaderData() as { data: { term: string } };
  const { data, loading } = useQuery(GET_SEARCH_ITEM, { variables: { term, stocked } });
  const { t } = useTranslation();

  const handleStockedChange = () => {
    setStocked(!stocked);
  };

  if (loading || !data?.search?.items) return <Loader />;

  return (
    <div id="searchResults">
      <div className="header">
        <h1>
          {t("searchResults")}: {term}
        </h1>
      </div>
      <div className="filters">
        <label className="checkbox">
          <input type="checkbox" checked={stocked || false} name="backInStock" onChange={handleStockedChange} />
          {t("Hide out of stock items")}
        </label>
      </div>
      <div className="content">
        <div className="entries">
          {data.search.items.length === 0 ? <p>No results...</p> : null}
          {data.search.items.map(e => (
            <Tile key={e.id} item={e} />
          ))}
        </div>
      </div>
    </div>
  );
};

const DataLoader = async (props: { request: { url: string } }) => {
  const { term } = new URI(props.request.url).search(true);
  return { data: { term } };
};

const Routes = [
  {
    path: "/search",
    element: <Search />,
    loader: DataLoader
  }
];

export default Search;
export { Routes };
