import { gql } from "../../__generated__/gql";

gql(`
  fragment CheckoutFields on Checkout {
    _id
    id
    created
    origin
    status
    buyer {
      _id
      email
      firstName
      id
      lastName
      name
      telephone
      organisation
      taxNumber
    }
    shipping {
      status
      date
      price {
        base
      }
      method
      methods  {
        name
        total
      }
      freeShippingThreshold
      address {
        ...AddressFields
      }
      tracking
      shopCollection
      weight
    }
    billing {
      status
      address {
        ...AddressFields
      }
      voucher {
        id
        redeemType
        expires
        value
      }
    }
    orderId
    items {
      ...CheckoutLineItemFields
    }
    totals {
      grand
      leftToPay
      discount
      itemQuantity
      subtotal
      subtotalBeforeTaxes
      shipping
      taxes {
        name
        amount
      }
    }
    urls {
      storefront
      checkout
    }
  }
`);

export const GET_CHECKOUT = gql(`
  query checkout($id: String!) {
    checkout(id: $id) {
      ...CheckoutFields
    }
}`);

export const POST_CHECKOUT = gql(`
  mutation checkoutCreate{
    checkoutCreate {
      ...CheckoutFields
    }
}`);

export const POST_CHECKOUT_UPDATE = gql(`
  mutation checkoutUpdate($id: String!, $buyer: CheckoutBuyerInput, $shipping: CheckoutShippingInput, $billing: CheckoutBillingInput) {
    checkoutUpdate(id: $id, buyer: $buyer, shipping: $shipping, billing: $billing) {
      ...CheckoutFields
    }
}`);

export const POST_CHECKOUT_VERIFY = gql(`
  mutation checkoutVerifyAndUpdate($id: String!) {
    checkoutVerifyAndUpdate(id: $id) {
      checkout {
        ...CheckoutFields
      }
      message
    }
  }
`);

export const POST_CHECKOUT_UPDATE_ITEMS = gql(`
  mutation checkoutUpdateItems($id: String!, $items: [CheckoutItemInput]) {
    checkoutUpdateItems(id: $id, items: $items) {
      ...CheckoutFields
    }
  }
`);

export const POST_VALIDATE_VOUCHER = gql(`
  mutation validateVoucher($id: String!, $voucherId: String!) {
    validateVoucher(id: $id, voucherId: $voucherId) {
      id
    }
  }
`);

export const ADD_VOUCHER = gql(`
  mutation addVoucher($id: String!, $voucherId: String!) {
    addVoucher(id: $id, voucherId: $voucherId) {
      ...CheckoutFields
    }
  }
`);

export const REMOVE_VOUCHER = gql(`
  mutation removeVoucher($id: String!) {
    removeVoucher(id: $id) {
      ...CheckoutFields
    }
  }
`);
