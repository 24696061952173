import React from "react";
import Meta from "../components/meta";

const Four0Four = () => {
  return (
    <div id="four0Four">
      <Meta fourOFour={true} index={false} />
      <div className="content">
        <h1>404</h1>
      </div>
    </div>
  );
};

export default Four0Four;
