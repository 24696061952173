import "./layout.scss"; // Factory layout read only
import "./index.scss";
import "./custom.scss";

import React, { Suspense, useEffect } from "react";
import { createBrowserRouter, RouterProvider, Outlet, useNavigate } from "react-router-dom";

import { Routes as articleRoutes } from "./routes/article";
import { Routes as itemRoutes } from "./routes/item";
import { Routes as collectionRoutes } from "./routes/collection";
import { Routes as catalogueRoutes } from "./routes/catalogue.routes";
import { Routes as legalRoutes } from "./routes/legal";
import { Routes as searchRoutes } from "./routes/search";
import { Routes as artistsRoutes } from "./routes/artists";
import { Routes as labelsRoutes } from "./routes/labels";
import { Route as verifyRoute } from "./routes/verify";
import { Route as unsubscribeRoute } from "./routes/unsubscribe";
import { Route as thankYouRoute } from "./routes/thankyou";
import { HomeWrapper } from "./routes/home";

import Maintenance, { PrivateBrowsing } from "./routes/maintenance";
import Header from "./components/header";
import Four0Four from "./routes/404";
import SuperHeader from "./components/superHeader";
import Menu from "./components/menu";
import { Store } from "./store";
import ConfigContext from "./configContext";
import CookiesComponent from "./components/cookies";
import Player from "./playerContext";
import SessionContext from "./sessionContext";
import CheckoutContext from "./checkoutContext";
import { ModalProvider } from "react-modal-hook";
import useAnalytics from "./hooks/useAnalytics";
import useResponsive from "./hooks/useResponsive";
import { Config, Theme } from "./__generated__/graphql";
import Loader from "./components/loader";

export default function AppWrapper() {
  return (
    <ConfigContext>
      <CheckoutContext>
        <SessionContext />
        <Suspense fallback={<p></p>}>
          <App />
        </Suspense>
      </CheckoutContext>
    </ConfigContext>
  );
}

const App = () => {
  const config = Store.useState(s => s.config);
  const theme = Store.useState(s => s.theme);

  if (!config || !theme) return <h1 style={{ textAlign: "center", marginTop: "20%" }}></h1>;

  const settings = theme.settings;
  const homepage = config.eshop.preferences.homepage;

  const router = createBrowserRouter([
    {
      path: "/home",
      element: <HomeWrapper />
    },
    {
      path: "/",
      element: <Layout config={config} theme={theme as Theme} />,
      errorElement: <Error />,
      children: [
        ...catalogueRoutes(homepage),
        ...collectionRoutes(homepage),
        ...itemRoutes,
        ...legalRoutes,
        ...articleRoutes({ articles: config.articles, homepage }),
        ...searchRoutes,
        ...artistsRoutes,
        ...labelsRoutes,
        ...unsubscribeRoute,
        ...thankYouRoute,
        { path: "/login", element: <Login /> },
        verifyRoute,
        {
          path: "*",
          element: <Four0Four />
        }
      ]
    }
  ]);

  return (
    <Maintenance config={config}>
      <div id="app" style={{ backgroundColor: `var(--${settings?.backgroundColor?.id})` }}>
        <RouterProvider router={router} />
      </div>
    </Maintenance>
  );
};

const Layout = ({ config, theme }: { config: Config; theme: Theme }) => {
  const session = Store.useState(s => s.session);

  const settings = theme.settings;
  const { isMobile } = useResponsive({ maxWidth: settings?.mobileBreakpoint || 940 });

  useAnalytics();
  useEffect(() => {
    Store.update(s => {
      s.isMobile = isMobile;
    });
  }, [isMobile]);

  const privateBrowsing = config.eshop?.preferences?.access?.private?.enabled;

  if (privateBrowsing) {
    if (session === undefined) return <Loader />;
    if (session === null) return <PrivateBrowsing config={config} />;
  }
  return (
    <ModalProvider>
      <CookiesComponent />
      <SuperHeader />
      <Header />
      <div id="content" className={`${settings?.layout?.id || "centered"} ${isMobile ? "mobile" : ""}`}>
        <Menu location="navbar" layout={settings?.navbar?.layout?.id} />
        <main style={{ gridArea: "main" }}>
          <Outlet />
        </main>
      </div>
      <Menu location="footer" layout="" />
      <Player />
    </ModalProvider>
  );
};

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/#basket");
  }, []);

  return null;
};

const Error = () => <h1>{"Error"}</h1>;
