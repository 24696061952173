import React from "react";
import { ConfigEshopPreferencesHomepage } from "../__generated__/graphql";
import Catalogue from "./catalogue";

const Routes = (homepage?: ConfigEshopPreferencesHomepage | null) => {
  const routes = [
    {
      // to avoid
      path: "/catalogue",
      element: <Catalogue isHomepage={false} />
    },
    {
      path: "/catalogue/:id",
      element: <Catalogue isHomepage={false} />
    },
    {
      // to remove replaced by /catalogue/products
      path: "/products",
      element: <Catalogue isHomepage={false} />
    },
    {
      // to remove
      path: "/preorder",
      element: <Catalogue isHomepage={false} />
    },
    {
      // to remove replaced by /catalogue/wantlist
      path: "/wantlist",
      element: <Catalogue isHomepage={false} />
    }
  ];

  if (homepage?.type === "catalogue") routes.push({ path: "/", element: <Catalogue isHomepage={true} /> });

  return routes;
};

export { Routes };
