import { gql } from "../../__generated__/gql";

gql(`
  fragment ConfigFields on Config {
    _id
    id
    uri
    domain
    currency
    currencySymbol
    currencySymbolPosition
    shopName
    modules {
      data
      id
    }
    sites {
      domain
    }
    eshop {
      menus
      theme {
        selected {
          _id
          css
          js
          tags
          version
          fontFaces
          colors {
            presets {
              ...ColorFields
            }
          }
          pages {
            item {
              suggestions {
                display
              }
              layout {
                id
                title
              }
            }
          }
          buttons {
            primary {
              ...ButtonFields
            }
            buy {
              ...ButtonFields
            }
          }
          player {
            active
            backgroundColor {
              ...ColorFields
            }
            waveformColor {
              ...ColorFields
            }
            progressColor {
              ...ColorFields
            }
            cursorColor {
              ...ColorFields
            }
            cursorWidth
            barRadius
            barWidth
            barGap
          }
          settings {
            maxWidth
            mobileBreakpoint
            borderRadius
            gutter {
              mobile
              tablet
              desktop
            }
            collectionColumns {
              desktop
              mobile
              tablet
            }
            logo {
              width
            }
            layout {
              id
              title
            }
            superHeader {
              display
              backgroundColor {
                ...ColorFields
              }
            }
            carousel {
              transitionType
            }
            header {
              backgroundColor {
                ...ColorFields
              }
              logoWidth {
                mobile
                tablet
                desktop
              }
              sticky
              layout {
                id
                title
              }
            }
            navbar {
              layout {
                id
                title
              }
            }
            footer {
              layout {
                id
                title
              }
              backgroundColor {
                id
                value
                name
              }
            }
            backgroundColor {
              id
              value
              name
            }
          }
          styles {
            _id
            title
            css
          }
          icons {
            fontUri
            format
          }
        }
      }
      checkout {
        version
      }
      head {
        tags
      }
      seo {
        title
        description
        favicon
        image
        robots
        index
        indexCatalogue
      }
      preferences {
        access {
          private {
            enabled
            message
          }
        }
        languages {
          whitelist
        }
        homepage {
          type
          handle
        }
        player {
          enabled
        }
        wantlist {
          enabled
        }
        suggestions {
          enabled
        }
      }
    }
    google {
      analytics {
        view {
          id
          name
        }
        property {
          id
          name
          tracker
        }
      }
    }
    articles {
      _id
      id
      handle
    }
    socials {
      id
      value
      title
    }
    taxes {
      rules {
        editPricesBeforeTaxes
        showPricesBeforeTaxes
      }
    }
  }
`);

export const GET_CONFIG = gql(`
  query config($domain: String, $themeRef: ID) {
    config(domain: $domain, themeRef: $themeRef) {
      ...ConfigFields
    }
  }
`);
