import React, { PropsWithChildren, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import clone from "clone";
import { Store } from "./store";
import Helmet from "react-helmet";
import ReactGA4 from "react-ga4";
import TagManager from "react-gtm-module";
import Parse from "html-react-parser";
import "./settings.json";
import { GET_CONFIG } from "./graphql/queries/config";
import { Theme, ThemeButton } from "./__generated__/graphql";
import { getConfigProperty } from "./utils";
import URI from "urijs";
import packageJson from "../package.json";
import { DateTime } from "luxon";

const isProduction = process.env.REACT_APP_BUILD_ENV === "production";

export default (props: PropsWithChildren) => {
  const uri = URI(window.location.href);
  const themeRef = uri.search(true)?.theme;
  const { data, refetch } = useQuery(GET_CONFIG, { fetchPolicy: "no-cache", variables: { themeRef } });
  const [fontFaces, setFontFaces] = useState<string>("");
  const [customCss, setCustomCss] = useState<string>("");
  const [stylesheet, setStylesheet] = useState<string>("");
  const [js, setJs] = useState<string>("");
  const [theme, setTheme] = useState<Theme | null>(null);

  const configFromApi = data?.config;

  useEffect(() => {
    window.addEventListener(
      "message",
      (event: any) => {
        if (event.data?.theme) {
          setTheme(event.data.theme as Theme);
        }
        if (typeof event.data?.css === "string") {
          setStylesheet(event.data.css);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!js) return;
    setTimeout(() => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = js;
      script.id = "customJS";
      document.body.appendChild(script);
    }, 100);
  }, [js]);

  // Every time we reload, CSS gets injected
  useEffect(() => {
    if (!configFromApi) return;
    const clonedConfig = clone(configFromApi);
    console.log(`© ${DateTime.now().year} Common Ground All rights reserved - Eshop v${packageJson.version}`);

    const googleTagManager = getConfigProperty(clonedConfig, "googleAnalytics", "gtm");
    if (googleTagManager) TagManager.initialize({ gtmId: googleTagManager });

    const GATracker = clonedConfig.google?.analytics?.property?.tracker;

    const testMode = !isProduction;

    const trackers = [{ trackingId: "G-SY3JXR6N3C", testMode }];
    if (GATracker) trackers.push({ trackingId: GATracker, testMode });

    if (!isProduction) console.log("Trackers", trackers, "Test mode:", testMode);
    ReactGA4.initialize(trackers);

    ReactGA4.set({ appName: getConfigProperty(clonedConfig, "information", "shopName") });
    ReactGA4.set({ appId: String(clonedConfig.id) });
    ReactGA4.set({ hostname: clonedConfig.uri });
    ReactGA4.set({ user_properties: { origin: clonedConfig.uri } });

    setTheme(configFromApi.eshop.theme.selected);

    Store.update(s => {
      s.config = clonedConfig;
      s.reloadConfig = refetch;
    });
  }, [configFromApi]);

  useEffect(() => {
    if (!theme) return;
    const root = document.documentElement;

    let headCss = "";

    if (theme.settings?.collectionColumns) {
      headCss += `
        @media only screen and (max-width: 840px) {
          :root {
            --collection-columns: ${theme.settings.collectionColumns.mobile};
          }
        }
        @media only screen and (min-width: 840px) {
          :root {
            --collection-columns: ${theme.settings.collectionColumns.tablet};
          }
        }
        @media only screen and (min-width: 1080px) {
          :root {
            --collection-columns: ${theme.settings.collectionColumns.desktop};
          }
        }
      `;
    }

    if (theme.settings?.gutter) {
      headCss += `
        @media only screen and (max-width: 840px) {
          :root {
            --gutter: ${theme.settings.gutter.mobile}px;
          }
        }
        @media only screen and (min-width: 840px) {
          :root {
            --gutter: ${theme.settings.gutter.tablet}px;
          }
        }
        @media only screen and (min-width: 1080px) {
          :root {
            --gutter: ${theme.settings.gutter.desktop}px;
          }
        }
      `;
    }

    if (theme.colors && theme.colors.presets) {
      headCss += `
        :root {
          ${theme.colors.presets.map(p => `--${p?.id}: ${p?.value};`).join("\n")}
        }
      `;
    }

    if (theme.buttons) {
      const getButtonProps = (selector: string, button: ThemeButton) => `
        ${selector} {
          ${button.backgroundColor ? `background-color: var(--${button.backgroundColor.default?.id});` : ""}
          ${
            button.border
              ? `
            border-radius: ${button.border.radius || 0}px;
            border-width: ${button.border.width || 0}px;
            border-style: ${button.border.style || "none"};
            ${button.border.color ? `border-color: var(--${button.border.color.default?.id});` : ""};
          `
              : ""
          }
          ${
            button.padding
              ? `
            padding: ${button.padding.vertical || 0}px ${button.padding.horizontal || 0}px;
          `
              : ""
          }
        }
        ${selector}:hover {
          ${button.backgroundColor?.hover ? `background-color: var(--${button.backgroundColor.hover.id});` : ""}
          ${button.border?.color?.hover ? `border-color: var(--${button.border.color.hover.id});` : ""}
        }
        ${selector}:active {
          ${button.backgroundColor?.active ? `background-color: var(--${button.backgroundColor.active.id});` : ""}
          ${button.border?.color ? `border-color: var(--${button.border.color.active?.id});` : ""}
        }
      `;

      if (theme.buttons.primary) {
        headCss += getButtonProps("button.primary", theme.buttons.primary);
      }
      if (theme.buttons.buy) {
        headCss += getButtonProps("#item .itemButton", theme.buttons.buy);
      }
    }

    if (theme.icons) {
      headCss += `
        @font-face {
          font-family: "cg-icons";
          src: url("${theme.icons.fontUri}?v=2")
            format("${theme.icons.format}");
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }
      `;
    } else {
      headCss += `
        @font-face {
          font-family: "cg-icons";
          src: url("https://static.common-ground.io/common/fonts/eshop/cg-font-eshop-v2.woff2?v=2")
            format("woff2");
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }
      `;
    }

    setCustomCss(headCss);
    setStylesheet(theme.css || "");
    setJs(theme.js || "");

    if (theme.settings?.maxWidth) root.style.setProperty("--max-width", `${theme.settings.maxWidth}px`);
    if (theme.settings?.borderRadius) root.style.setProperty("--border-radius", `${theme.settings.borderRadius}px`);

    let styleElementContent = "";
    if (theme.styles && theme.styles.length) {
      styleElementContent = theme.fontFaces || "";
      for (const style of theme.styles) {
        styleElementContent += style.css;
      }
      setFontFaces(styleElementContent);
    }

    Store.update(s => {
      s.theme = theme;
    });
  }, [theme]);

  const headTags = configFromApi?.eshop?.head?.tags;
  return (
    <>
      <Helmet>
        {configFromApi ? <title>{configFromApi?.shopName}</title> : null}
        {fontFaces ? <style rel="stylesheet">{fontFaces}</style> : null}
        {customCss ? <style rel="stylesheet">{customCss}</style> : null}
        {stylesheet ? <style rel="stylesheet">{stylesheet}</style> : null}
        {headTags ? Parse(headTags.join(""), {}) : null}
        {theme?.tags ? Parse(theme?.tags?.join(""), {}) : null}
      </Helmet>
      {props.children}
    </>
  );
};
