import React from "react";
import { useLocation } from "react-router-dom";
import Meta from "../components/meta";
import { useTranslation } from "react-i18next";
import URI from "urijs";

const ThankYou = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const uri = new URI(location.pathname + location.search);
  const args = uri.search(true);
  const orderId = args && args.order;

  return (
    <div id="thankyou">
      <Meta title="Thank you" description="Thanks for your order" index={false} />
      <div className="content">
        <h1>{t("thanksForYourOrder")}</h1>
        <h2>{t("aConfirmationWasSent")}</h2>
        {orderId ? (
          <p>
            {t("Order ID")}
            {":"} #{orderId}
          </p>
        ) : null}
      </div>
    </div>
  );
};

const Route = [
  {
    path: "/thankyou",
    element: <ThankYou />
  }
];

export default ThankYou;
export { Route };
