import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RegisterMethod, Store, VerifyMethod } from "../../store";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { TFunction, t } from "i18next";
import clone from "clone";
import useNotification, { AddNotification } from "../../hooks/useNotification";
import Price from "../price";
import { DateTime } from "luxon";
import base64 from "base-64";
import { getName } from "country-list";
import Cookies from "js-cookie";
import URI from "urijs";
import { useDefaultImage } from "../../hooks/useInventory";
import { getConfigProperty } from "../../utils";
import {
  GET_COUNTRIES,
  GET_USER,
  POST_BUYER_DEACTIVATE,
  POST_PASSWORD_USER_SESSION,
  POST_USER_CREATE_PASSWORD_TOKEN,
  POST_USER_PWD_RESET,
  POST_USER_UPDATE
} from "../../graphql/queries/user";
import { GET_ORDER, GET_ORDERS, POST_ORDER_MESSAGE } from "../../graphql/queries/order";
import { Address, Checkout, CheckoutListingOption, Config, Order, Session, User } from "../../__generated__/graphql";

type Country = {
  code: string;
  name: string;
};

const Home = ({ isMobile, exit }: { isMobile: boolean; exit: () => void }) => {
  const { addNotification } = useNotification();
  const {
    session,
    logout,
    login,
    config,
    register,
    verify,
    checkout,
    checkoutUpdateQuantity,
    resetStore,
    reloadConfig,
    createNewCheckout
  } = Store.useState(s => s);
  const { t } = useTranslation();
  const isLoggedIn = !!session;
  const targetRef = React.createRef<HTMLDivElement>();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUri = new URI(location.pathname + location.search + location.hash);
  const tabs = ["basket", "orders", "details", "addresses", "settings"];
  const search = currentUri.search(true);
  const state = (currentUri.hash() || "basket").replace("#", "");

  const handleStateChange = (state: string) => {
    currentUri.hash(state);
    navigate(currentUri.toString());
  };

  const handleLogout = async () => {
    handleStateChange("basket");
    if (logout) await logout();
  };

  useEffect(() => {
    if (session && state === "resetPassword") {
      if (logout) logout();
    }
  }, [session, state]);

  useEffect(() => {
    if (search.logout !== undefined && session) {
      if (logout) logout();
      navigate("/#basket");
    }
  }, [session, search]);

  if (!isLoggedIn)
    return (
      <div id="home" ref={targetRef} className={`${isMobile ? "mobile" : ""}`}>
        <div className="header">
          <div className="top">
            <div className="left">
              <h2>{t("home")}</h2>
              {state !== "basket" && !session ? (
                <button onClick={() => handleStateChange("basket")}>{t("backToBasket")}</button>
              ) : state === "basket" && !isLoggedIn ? (
                <button onClick={() => handleStateChange("login")}>{t("login")}</button>
              ) : null}
            </div>
            <div className="right">
              <button type="button" onClick={exit}>
                <i className="cg-icon-close" />
              </button>
            </div>
          </div>
        </div>
        <div className="content">
          {state === "login" ? (
            <Login t={t} login={login} setState={handleStateChange} addNotification={addNotification} config={config as Config} />
          ) : null}
          {state === "basket" ? (
            <Basket
              logout={logout as () => void}
              setState={handleStateChange}
              session={session}
              exit={exit}
              isMobile={isMobile}
              checkout={checkout as Checkout}
              config={config as Config}
              checkoutUpdateQuantity={checkoutUpdateQuantity as any}
            />
          ) : null}
          {state === "resetPassword" ? <ResetPassword addNotification={addNotification} setState={handleStateChange} /> : null}
          {state === "register" ? (
            <Register
              checkout={checkout as Checkout}
              verify={verify as any}
              register={register as any}
              setState={handleStateChange}
              addNotification={addNotification}
            />
          ) : null}
        </div>
      </div>
    );

  const handleTabChange = (index: number) => {
    handleStateChange(tabs[index]);
  };

  const selectedTab = tabs.indexOf(state) || 0;
  return (
    <div id="home" ref={targetRef} className={`${isMobile ? "mobile" : ""} authenticated`}>
      <div className="header">
        <div className="top">
          <div className="left">
            <h2>{session.user.name}</h2>
            <button onClick={handleLogout}>{t("logout")}</button>
          </div>
          <div className="right">
            {isLoggedIn ? (
              <Link to="/wantlist?stock=all" onClick={exit}>
                {t("myWantlist")}
              </Link>
            ) : null}
            <button type="button" onClick={exit}>
              <i className="cg-icon-close" />
            </button>
          </div>
        </div>
        <div className="tabList">
          <button className={`tab ${selectedTab === 0 ? "active" : ""}`} onClick={() => handleTabChange(0)}>
            {t("basket", "Basket")}
          </button>
          <button className={`tab ${selectedTab === 1 ? "active" : ""}`} onClick={() => handleTabChange(1)}>
            {t("myOrders", "My orders")}
          </button>
          <button className={`tab ${selectedTab === 2 ? "active" : ""}`} onClick={() => handleTabChange(2)}>
            {t("accountDetails", "Account details")}
          </button>
          <button className={`tab ${selectedTab === 3 ? "active" : ""}`} onClick={() => handleTabChange(3)}>
            {t("myAddresses", "My addresses")}
          </button>
          <button className={`tab ${selectedTab === 4 ? "active" : ""}`} onClick={() => handleTabChange(4)}>
            {t("settings", "Settings")}
          </button>
        </div>
      </div>
      <div className="content">
        {selectedTab === 0 ? (
          <Basket
            setState={handleStateChange}
            logout={handleLogout}
            session={session}
            exit={exit}
            isMobile={isMobile}
            checkout={checkout as Checkout}
            config={config as Config}
            checkoutUpdateQuantity={checkoutUpdateQuantity as any}
          />
        ) : null}
        {selectedTab === 1 ? <Orders config={config as Config} t={t} addNotification={addNotification} /> : null}
        {selectedTab === 2 ? <Details config={config as Config} session={session} t={t} addNotification={addNotification} /> : null}
        {selectedTab === 3 ? <Addresses session={session} t={t} addNotification={addNotification} /> : null}
        {selectedTab === 4 ? (
          <Settings
            config={config as Config}
            logout={handleLogout}
            t={t}
            addNotification={addNotification}
            reloadConfig={reloadConfig}
            resetStore={resetStore as () => void}
            createNewCheckout={createNewCheckout}
          />
        ) : null}
      </div>
    </div>
  );
};

const getTranslatedOption = ({ t, option }: { t: TFunction; option: CheckoutListingOption }) => {
  if (option.name === "Media Condition") return t("mediaCondition");
  else if (option.name === "Sleeve Condition") return t("sleeveCondition");
  else return option.name;
};

const Settings = ({
  t,
  config,
  addNotification,
  reloadConfig,
  resetStore,
  createNewCheckout,
  logout
}: {
  t: TFunction;
  config: Config;
  addNotification: AddNotification;
  reloadConfig: () => void;
  resetStore: () => void;
  createNewCheckout: () => void;
  logout: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClosingAccount, setIsClosingAccount] = useState(false);
  const [closeAccount] = useMutation(POST_BUYER_DEACTIVATE);

  const onClickCloseAccount = async () => {
    setIsLoading(true);
    if (window.confirm(t("closeAccountConfirmation") as string)) {
      try {
        await closeAccount();
        Cookies.remove("jwt");
        Store.update(s => {
          s.session = undefined;
        });
        await reloadConfig();
        await resetStore();
        await createNewCheckout();
        addNotification({ ok: 1, message: t("closeAccountSuccess") });
      } catch (e: any) {
        addNotification({ ok: 0, message: e.toString() });
      }
    }
  };

  if (isClosingAccount)
    return (
      <div id="settings">
        <section className="closing">
          <div className="header">
            <h3>{t("closeAccountPermanently")}</h3>
          </div>
          <div className="content">
            <p>{t("closeAccountDescription", { shopName: getConfigProperty(config, "information", "shopName") })}</p>
          </div>
          <div className="actions">
            <button disabled={isLoading} onClick={() => setIsClosingAccount(false)} className="corporate">
              {t("cancel")}
            </button>
            <button disabled={isLoading} onClick={onClickCloseAccount} className="corporate danger">
              {t("confirm")}
            </button>
          </div>
        </section>
      </div>
    );

  return (
    <div id="settings">
      <section className="closeAccount">
        <div className="actions">
          <button disabled={isLoading} onClick={() => setIsClosingAccount(true)} className="corporate">
            {t("closeMyAccount")}
          </button>
          <button disabled={isLoading} onClick={() => logout()} className="corporate">
            {t("logout")}
          </button>
        </div>
      </section>
    </div>
  );
};

const Addresses = ({ t, addNotification, session }: { t: TFunction; addNotification: AddNotification; session: Session }) => {
  const { loading, data } = useQuery(GET_USER);
  const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);
  const [dirty, setDirty] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);

  const [userUpdate, { loading: updateLoading }] = useMutation(POST_USER_UPDATE);
  const isLoading = updateLoading;

  useEffect(() => {
    if (data && data.user) setUserToEdit(clone(data.user));
  }, [data]);

  const handleSubmitDetails = async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (!dirty || !userToEdit || !Array.isArray(userToEdit.addresses)) return;
    try {
      const dataToSubmit = {
        addresses: userToEdit.addresses.map(a => ({
          addressLine1: a?.addressLine1,
          addressLine2: a?.addressLine2,
          city: a?.city,
          alpha2: a?.alpha2,
          postCode: a?.postCode,
          state: a?.state,
          description: a?.description
        }))
      };
      await userUpdate({ variables: dataToSubmit });
      addNotification({ ok: 1, message: "Profile updated" });
      setSelectedAddressIndex(-1);
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleAddressChange = async (name: string, value: string, index: number) => {
    const address = userToEdit?.addresses[index];
    if (!address) return;
    // @ts-expect-error no index
    address[name] = value;
    setUserToEdit({ ...userToEdit });
    setDirty(true);
  };

  const handleAddAddress = () => {
    setDirty(true);
    if (!userToEdit) return;
    if (!userToEdit.addresses) userToEdit.addresses = [];
    userToEdit.addresses.push({ description: "New address", type: "billingAndShipping" });
    setUserToEdit(clone(userToEdit));
    setSelectedAddressIndex(userToEdit.addresses.length - 1);
  };

  const handleCancelNewAddress = () => {
    if (!userToEdit) return;
    userToEdit.addresses.splice(userToEdit.addresses.length - 1, 1);
    setUserToEdit(clone(userToEdit));
    setSelectedAddressIndex(-1);
  };

  const handleDeleteAddress = async (i: number) => {
    if (!userToEdit) return;
    setDirty(true);
    const addressToDelete = userToEdit.addresses[i];
    userToEdit.addresses.splice(i, 1);
    setUserToEdit(clone(userToEdit));
    setSelectedAddressIndex(-1);
    if (addressToDelete._id) await handleSubmitDetails();
  };

  if (!userToEdit || loading) return null;

  const selectedAddress = selectedAddressIndex !== -1 ? userToEdit.addresses[selectedAddressIndex] : null;
  if (selectedAddress)
    return (
      <div id="addresses" key={session.user.email}>
        <form onSubmit={handleSubmitDetails} id="addressesForm">
          <div className="content">
            <AddressComponent index={selectedAddressIndex} t={t} address={selectedAddress} handleChange={handleAddressChange} />
          </div>
        </form>
        <div className="actions spread">
          <div>
            {selectedAddress._id ? (
              <button type="button" className="corporate" onClick={() => handleDeleteAddress(selectedAddressIndex)}>
                {t("delete")}
              </button>
            ) : null}
          </div>
          <div>
            <button
              type="button"
              className="reset"
              onClick={() => (selectedAddress._id ? setSelectedAddressIndex(-1) : handleCancelNewAddress())}
            >
              {t("cancel")}
            </button>
            <button disabled={isLoading} form="addressesForm" className="corporate" type="submit">
              {t("saveDetails")}
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div id="addresses" key={session.user.email}>
      <div className="addresseList">
        {userToEdit.addresses
          .filter(a => a._id)
          .map((a, idx) => (
            <AddressView key={a._id} index={idx} t={t} address={a} setSelectedAddressIndex={setSelectedAddressIndex} />
          ))}
      </div>
      <div className="actions">
        {userToEdit.addresses.length < 2 ? (
          <button disabled={isLoading} type="button" className="corporate" onClick={handleAddAddress}>
            {t("addANewAddress")}
          </button>
        ) : null}
      </div>
    </div>
  );
};

const AddressView = ({
  address,
  t,
  index,
  setSelectedAddressIndex
}: {
  address: Address;
  t: TFunction;
  index: number;
  setSelectedAddressIndex: (index: number) => void;
}) => {
  return (
    <div className="address">
      <address>
        <p>
          <strong>{address.description}</strong>
        </p>
        <p>
          {address.addressLine1} {address.addressLine2}
        </p>
        <p>
          {address.city} {address.postCode}
        </p>
        <p>
          {address.state} {getName(address.alpha2 as string)}
        </p>
      </address>
      <button className="corporate" onClick={() => setSelectedAddressIndex(index)} type="button">
        {t("edit")}
      </button>
    </div>
  );
};

const AddressComponent = ({
  address,
  t,
  handleChange,
  index
}: {
  address: Address;
  handleChange: (key: string, code: string, index: number) => void;
  t: TFunction;
  index: number;
}) => {
  const { data } = useQuery(GET_COUNTRIES);
  const typeValues = [
    { value: "billingAndShipping", name: "Billing & Shipping" },
    { value: "billing", name: "Billing" },
    { value: "shipping", name: "Shipping" }
  ];

  const handleStateChange = (code: string, idx: number) => {
    if (!selectedCountry) return;
    const foundZone = selectedCountry.zones.find((z: { code: string }) => z.code === code);
    if (foundZone) {
      handleChange("state", foundZone.name, idx);
      handleChange("stateCode", foundZone.code, idx);
    }
  };

  const countries = data?.countries?.countries || [];
  const selectedCountry = countries?.find((c: { code: string }) => c.code === address.alpha2);

  return (
    <div className="address">
      <div className="addressFields">
        <div className="twoColumns">
          <label>
            {t("description")}:
            <input
              type="text"
              name="description"
              required
              value={address.description || ""}
              onChange={e => handleChange(e.target.name, e.target.value, index)}
            />
          </label>
          <label>
            {t("type")}:
            <select onChange={e => handleChange("type", e.target.value, index)}>
              {typeValues.map(c => (
                <option key={c.value} value={c.value} selected={address.type === c.value}>
                  {c.name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="twoColumns">
          <label>
            {t("addressLine1")} 1:
            <input
              type="text"
              name="addressLine1"
              required
              value={address.addressLine1 || ""}
              onChange={e => handleChange(e.target.name, e.target.value, index)}
            />
          </label>
          <label>
            {t("addressLine2")}:
            <input
              type="text"
              name="addressLine2"
              value={address.addressLine2 || ""}
              onChange={e => handleChange(e.target.name, e.target.value, index)}
            />
          </label>
        </div>
        <div className="twoColumns">
          <label>
            {t("city")}:
            <input
              type="text"
              name="city"
              required
              value={address.city || ""}
              onChange={e => handleChange(e.target.name, e.target.value, index)}
            />
          </label>
          <label>
            {t("postCode")}:
            <input
              type="text"
              name="postCode"
              required
              value={address.postCode || ""}
              onChange={e => handleChange(e.target.name, e.target.value, index)}
            />
          </label>
        </div>
        <div className="twoColumns">
          <label>
            {t("country")}:
            <select onChange={e => handleChange("alpha2", e.target.value, index)}>
              {countries.map((c: Country) => (
                <option key={c.code} value={c.code} selected={address.alpha2 === c.code}>
                  {c.name}
                </option>
              ))}
            </select>
          </label>
          <label>
            {t("state")}:
            {selectedCountry?.zones?.length ? (
              <select onChange={e => handleStateChange(e.target.value, index)}>
                {selectedCountry.zones.map((c: Country) => (
                  <option key={c.code} value={c.code} selected={address.stateCode === c.code}>
                    {c.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name="state"
                required
                value={address.state || ""}
                onChange={e => handleChange(e.target.name, e.target.value, index)}
              />
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

const Details = ({
  t,
  addNotification,
  session,
  config
}: {
  t: TFunction;
  addNotification: AddNotification;
  session: Session;
  config: Config;
}) => {
  const { loading, data } = useQuery(GET_USER);
  const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);
  const [state, setState] = useState("details");
  const [dirty, setDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);

  const [userUpdate, { loading: updateLoading }] = useMutation(POST_USER_UPDATE);
  const isLoading = updateLoading;
  const languages = config.eshop.preferences?.languages?.whitelist || [];

  useEffect(() => {
    if (data && data.user) {
      const cloned = clone(data.user);
      if (!cloned.preferences || !cloned.preferences.preferredLanguage)
        cloned.preferences = {
          preferredLanguage: languages[0],
          notifications: { backInStock: { email: false } }
        };
      setUserToEdit(cloned);
    }
  }, [data]);

  const handleUserDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    // @ts-expect-error because of index
    setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value });
  };

  const handleSubmitDetails = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = { ...userToEdit, addresses: undefined };
      const { data } = await userUpdate({ variables: dataToSubmit });
      const user = data?.user;
      if (user) {
        setUserToEdit(clone(user));
        addNotification({ ok: 1, message: "Profile updated" });
        setDirty(false);
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleSubmitEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      // @ts-expect-error because of index
      const dataToSubmit = { email: e.target.email.value };
      if (dataToSubmit.email === session.user.email) return addNotification({ ok: 0, message: "Emails are identical" });
      await userUpdate({ variables: dataToSubmit });
      addNotification({ ok: 1, message: "Email updated" });
      setEmailDirty(false);
      setState("details");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleUserDetailsBackInStockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    // @ts-expect-error because of index
    setUserToEdit({ ...userToEdit, preferences: { notifications: { backInStock: { email: e.target.checked } } } });
  };

  const handleSubmitPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      // @ts-expect-error because of index
      const password1 = e.target.password.value;
      // @ts-expect-error because of index
      const password2 = e.target["password-confirmation"].value;
      if (password1.length < 6) return addNotification({ ok: 0, message: "Password must be at least 6 characters long" });
      if (password1 && password2) if (password1 !== password2) throw new Error("Passwords do not match");
      await userUpdate({ variables: { password: base64.encode(password1) } });
      addNotification({ ok: 1, message: "Password updated" });
      setPasswordDirty(false);
      setState("details");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDirty(true);
    const userPreferences = userToEdit?.preferences;
    if (userPreferences) userPreferences.preferredLanguage = e.target.value;
    // @ts-expect-error because of index
    setUserToEdit({ ...userToEdit });
  };

  if (!userToEdit || loading) return null;

  if (state === "emailUpdate")
    return (
      <div id="details" className="emailUpdate">
        <div className="content">
          <form onSubmit={handleSubmitEmail} id="detailsForm">
            <label>
              {t("email")}:
              <input type="text" name="email" onChange={() => setEmailDirty(true)} defaultValue={userToEdit.email || ""} required />
            </label>
            <div className="actions">
              <button type="button" onClick={() => setState("details")}>
                {t("cancel")}
              </button>
              <button type="submit" disabled={!emailDirty} className="corporate">
                {t("updateMyEmails", "Update my email")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  else if (state === "passwordUpdate")
    return (
      <div id="details" className="passwordUpdate">
        <div className="content">
          <form onSubmit={handleSubmitPassword} id="detailsForm">
            <label>
              {t("newPassword")}:
              <input
                type="password"
                name="password"
                defaultValue=""
                required
                placeholder={t("aSecurePassword") as string}
                autoComplete="off"
              />
            </label>
            <label>
              {t("confirmPassword")}:
              <input
                type="password"
                name="password-confirmation"
                onChange={() => setPasswordDirty(true)}
                required
                placeholder={t("confirmPassword") as string}
                autoComplete="off"
              />
            </label>
            <div className="actions">
              <button type="button" onClick={() => setState("details")}>
                {t("cancel")}
              </button>
              <button type="submit" disabled={!passwordDirty} className="corporate">
                {t("updateMyPassword")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  else if (state === "details")
    return (
      <div id="details" key={session.user.email}>
        <div className="content">
          <form onSubmit={handleSubmitDetails} id="detailsForm">
            <div className="threeColumns">
              <label>
                {t("firstName")}:
                <input type="text" name="firstName" required value={userToEdit.firstName || ""} onChange={handleUserDetailsChange} />
              </label>
              <label>
                {t("lastName")}:
                <input type="text" required name="lastName" value={userToEdit.lastName || ""} onChange={handleUserDetailsChange} />
              </label>
              <label>
                {t("organisation")}:
                <input type="text" name="organisation" value={userToEdit.organisation || ""} onChange={handleUserDetailsChange} />
              </label>
            </div>
            <div className="threeColumns">
              <label>
                {t("taxId")}:
                <input type="text" name="taxNumber" value={userToEdit.taxNumber || ""} onChange={handleUserDetailsChange} />
              </label>
              <label>
                {t("telephone")}:
                <input type="text" name="telephone" value={userToEdit.telephone || ""} onChange={handleUserDetailsChange} />
              </label>
            </div>
            <div className="twoColumns">
              <label>
                {t("email")}:
                <input type="text" name="email" readOnly={true} defaultValue={session.user.email || ""} required />
              </label>
            </div>
            <div className="twoColumns">
              <div>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={userToEdit.preferences?.notifications?.backInStock?.email || false}
                    name="backInStock"
                    onChange={handleUserDetailsBackInStockChange}
                  />
                  {t("sendBackInStockNotifications")}
                </label>
              </div>

              <label>
                {t("preferredLanguage")}:
                <select onChange={handleChange}>
                  {languages.map(c => (
                    <option key={c} value={c || ""} selected={c === userToEdit.preferences?.preferredLanguage}>
                      {(c || "").toUpperCase()}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </form>
        </div>
        <div className="actions">
          <button className="corporate" onClick={() => setState("passwordUpdate")}>
            {t("updateMyPassword")}
          </button>
          <button className="corporate" onClick={() => setState("emailUpdate")}>
            {t("updateMyEmail")}
          </button>
          <button disabled={isLoading || !dirty} form="detailsForm" className="corporate" type="submit">
            {t("saveDetails")}
          </button>
        </div>
      </div>
    );
  else return null;
};

const Orders = ({ config, t, addNotification }: { config: Config; t: TFunction; addNotification: AddNotification }) => {
  const { data, loading } = useQuery(GET_ORDERS, { fetchPolicy: "cache-and-network" });
  const [order, setOrder] = useState<Order | null | undefined>(undefined);

  const orders = data?.orders;

  if (loading || !orders) return null;

  if (order) return <OrderComponent id={order.id} goBack={() => setOrder(null)} config={config} t={t} addNotification={addNotification} />;

  return (
    <div className="orders">
      <div className="header">
        <h3>
          {orders.length ? (
            <>
              {t("myOrders")}({orders.length})
            </>
          ) : (
            <p>{t("noOrders")}</p>
          )}
        </h3>
      </div>
      <hr />
      <div className="orderTable">
        {orders.map(o => (
          <div key={`${o.id}`} className="orderTableItem">
            <>
              <div className="id">
                <p>#{o.id}</p>
              </div>
              <div className="origin">
                <p>{o.origin}</p>
              </div>
              <div className="status">
                <p>{o.status}</p>
              </div>
              <div className="created">
                <p>{DateTime.fromMillis(o.created).toFormat("DD")}</p>
              </div>
              <div className="total">
                <Price value={o.totals.grand} />
              </div>
              <div className="action">
                <button type="button" onClick={() => setOrder(o)} className="corporate">
                  {t("details")}
                </button>
              </div>
            </>
          </div>
        ))}
      </div>
    </div>
  );
};

const OrderComponent = ({
  config,
  id,
  t,
  addNotification,
  goBack
}: {
  config: Config;
  id: string;
  t: TFunction;
  addNotification: AddNotification;
  goBack: () => void;
}) => {
  const [newMessageContent, setNewMessageContent] = useState("");

  const { refetch, data, loading } = useQuery(GET_ORDER, { variables: { id }, fetchPolicy: "cache-and-network" });
  const [sendMessage, { loading: orderIsSendingMessage }] = useMutation(POST_ORDER_MESSAGE);

  const order = data?.order;
  const preTaxes = config.taxes.rules.showPricesBeforeTaxes;

  const handleAddMessage = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (orderIsSendingMessage || !order) return;
    // @ts-expect-error no index
    const newMessageContent = e.target.messageContent.value;
    if (!newMessageContent) return;
    try {
      await sendMessage({ variables: { id: order.id, message: newMessageContent } });
      refetch();
      addNotification({ ok: 1, message: "Message sent" });
      setNewMessageContent("");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // @ts-expect-error no index
    setNewMessageContent(e.target.messageContent);
  };

  if (loading) return null;
  else if (!order) {
    return (
      <div id="order">
        <div id="content">
          <h2>{t("noOrders")}</h2>
        </div>
      </div>
    );
  }

  return (
    <div id="order">
      <div className="content">
        <div className="left">
          <div className="header">
            <button type="button" onClick={goBack}>
              {"<"}
            </button>
            <h3>#{order.id}</h3>
          </div>
          <div className="summary">
            <hr />
            <p className="justify">
              <span>{t("status")}</span>
              <span>{order.status}</span>
            </p>
            {order.shipping && order.shipping.tracking ? (
              <p className="justify">
                <span>{t("Tracking")}</span>
                <span>
                  <a target="_tab" href={order.shipping.tracking}>
                    {t("trackMyPackage")}
                  </a>
                </span>
              </p>
            ) : null}
            <p className="justify">
              <span>{t("created")}:</span>
              <span>{DateTime.fromMillis(order.created).toFormat("DD")}</span>
            </p>
            {/* {order.packages &&
              order.packages.map((p, i) => (
                <p key={i} className="package justify">
                  <span>Package {i + 1} subtotal:</span>
                  <span>
                    <Price value={p.subtotal}></Price>
                  </span>
                </p>
              ))} */}
            <p className="justify">
              <span>{t("totalNumberOfItems")}:</span>
              <span>{order.totals.itemQuantity}</span>
            </p>
            <p className="justify">
              <span>{t("subtotal")}:</span>
              <span>
                <Price value={preTaxes ? order.totals.subtotalBeforeTaxes : order.totals.subtotal} />
              </span>
            </p>
            <p className="justify">
              {order.totals.taxes.map(tax => (
                <>
                  <span>{tax.name}:</span>
                  <span>
                    <Price value={tax.amount as number} />
                  </span>
                </>
              ))}
            </p>
            <p className="justify">
              <span>{t("shipping")}:</span>
              <span>
                {order && order.shipping && order.shipping.shopCollection ? (
                  "Shop collection"
                ) : (
                  <>
                    {(order.shipping && order.shipping.address && order.shipping.address.alpha2) || "NA"} -{" "}
                    <Price value={order.totals.shipping || 0} />
                  </>
                )}
              </span>
            </p>
            {order.totals.discount ? (
              <p className="justify">
                <span>{t("discount")}:</span>
                <Price value={-order.totals.discount} />
              </p>
            ) : null}
            {order.billing.invoice && order.billing.invoice.pdf ? (
              <p className="justify">
                <span>{"PDF"}</span>
                <span>
                  <a target="_tab" href={order.billing.invoice.pdf}>
                    {t("downloadPdf")}
                  </a>
                </span>
              </p>
            ) : null}
            {order.billing.paymentMethods?.length ? (
              <p className="justify">
                <span>{t("paymentMethods")}:</span>
                <span>
                  {order.billing.paymentMethods.map(m => (
                    <span key={m.origin}>
                      {m.origin} - <Price value={m.amount} />
                    </span>
                  ))}
                </span>
              </p>
            ) : null}
            <hr />
            <h3 className="justify total">
              <span>{t("total")}</span>
              <span className="price">
                <Price value={order.totals.grand}></Price>
              </span>
            </h3>
          </div>
          {order.billing.status === "Pending" && order.status !== "Invoice Sent" ? <p>{t("beingProcessed")}</p> : null}
          {order.paymentUrl ? (
            <a href={order.paymentUrl}>
              <button className="corporate fullWidth" type="button">
                {t("proceedToPayment")}
              </button>
            </a>
          ) : null}
        </div>
        <div className="right">
          <div className="items">
            {order.items.map((i, index) => (
              <div className="itemEntry" key={index}>
                <div className="image">
                  <Link to={`/item/${i.item.id}`}>
                    <img alt={i.item.description} src={i.item.thumbnail || ""} />
                  </Link>
                </div>
                <div className="description">
                  <Link to={`/item/${i.item.id}`}>
                    <p>{i.item.description}</p>
                  </Link>
                  <div className="options">
                    {i.listing.options && i.listing.options.length
                      ? i.listing.options.map(o => (
                          <p key={o.name}>
                            {getTranslatedOption({ option: o, t })}: {o.value}
                          </p>
                        ))
                      : null}
                  </div>
                </div>
                <p className="quantity">{i.quantity}</p>
                <div className="total">
                  <p>
                    <Price value={(preTaxes ? i.price.subtotalBeforeTaxes : i.price.subtotal) || 0} />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="messageSection">
            <div className="header">
              <h4>
                {t("newMessageTitle")} {getConfigProperty(config, "information", "shopName")}
              </h4>
            </div>
            <form className="newMessage" onSubmit={handleAddMessage}>
              <textarea rows={4} value={newMessageContent} onChange={handleMessageChange} name="messageContent" required />
              <div>
                <button type="submit" disabled={orderIsSendingMessage} className="corporate">
                  {orderIsSendingMessage ? "Sending..." : t("sendMessage")}
                </button>
              </div>
            </form>
            <div className="messages">
              {order.messages && order.messages.length ? (
                <>
                  <h4>{t("Messages")}</h4>
                  <hr />
                  {order.messages.map(m => (
                    <div key={m.created} className="message">
                      <p className="header">
                        {m.sender?.alias || m.sender?.name} - {DateTime.fromMillis(m.created).toFormat("DD")}
                      </p>
                      <p className="content" dangerouslySetInnerHTML={{ __html: m.content || "" }} />
                      <div className="attachments">
                        {m.attachments && m.attachments.length
                          ? m.attachments.map(a => (
                              <div key={a._id} className="attachment">
                                <a target="_blank" href={a.uri || ""} rel="noreferrer">
                                  {a.title}
                                </a>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Basket = ({
  config,
  checkout,
  session,
  logout,
  exit,
  setState,
  checkoutUpdateQuantity,
  readOnly = false,
  isMobile
}: {
  config: Config;
  checkout: Checkout;
  session?: Session | null;
  logout: () => void;
  exit: () => void;
  setState: (state: string) => void;
  checkoutUpdateQuantity: (id: number, quantity: number) => void;
  readOnly?: boolean;
  isMobile: boolean;
}) => {
  const preTaxes = config.taxes.rules.showPricesBeforeTaxes;
  const [isUpdating, setIsUpdating] = useState(false);
  const defaultImage = useDefaultImage();

  const handleQuantityUpdate = async (quantity: any, listingId: number, withSubmit?: boolean) => {
    if (quantity === "" || quantity === undefined || isUpdating) return;
    setIsUpdating(true);
    if (withSubmit) await checkoutUpdateQuantity(listingId, parseInt(quantity));
    else {
      const clonedCheckout = clone(checkout);
      const line = clonedCheckout.items.find(i => i.listing.id === listingId);
      if (line) line.quantity = parseInt(quantity);
      Store.update(s => {
        s.checkout = clonedCheckout;
      });
    }
    setIsUpdating(false);
  };

  const handleSubmitQuantity = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // @ts-expect-error no index
    await handleQuantityUpdate(parseInt(e.target.quantity.value), parseInt(e.target.listingId.value), true);
  };

  const checkoutUrl = checkout.urls?.checkout;

  const shopCollection = checkout.shipping.shopCollection;
  const shippingAddress = checkout.shipping.address || {};
  const placeBasket = getConfigProperty(config, "shipping", "placeBasket");

  if (!checkout.items.length)
    return (
      <div className="basket empty">
        <h2>{t("yourBasketIsEmpty")}</h2>
        <div className="buttons">
          <Link to="/" onClick={exit}>
            <button type="button" className="corporate">
              {t("browse")}
            </button>
          </Link>
          {session ? (
            <div>
              <Link to="/wantlist?stock=all" onClick={exit}>
                <button type="button" className="corporate">
                  {t("myWantlist")}
                </button>
              </Link>
            </div>
          ) : null}
          {!session ? (
            <div>
              <button type="button" className="corporate" onClick={() => setState("login")}>
                {t("login")}
              </button>
            </div>
          ) : (
            <button type="button" className="corporate" onClick={() => logout()}>
              {t("logout")}
            </button>
          )}
        </div>
      </div>
    );

  return (
    <div className={`${isMobile ? "mobile" : ""} basket`}>
      <div className="content">
        <div className="entries">
          <h2>
            {t("items")} ({checkout.items.length})
          </h2>
          <hr />
          {checkout.items.map(i => (
            <div key={i.listing.id} className="basketEntry">
              <div className="image">
                <Link to={i.item.path || ""}>
                  <img alt={i.item.description} src={i.item.thumbnail || defaultImage}></img>
                </Link>
              </div>
              <div className="descriptionAndOptions">
                <p className="description">
                  <Link to={i.item.path || ""}>{i.item.description}</Link>
                </p>
                <div className="options">
                  {i.listing.options.map(o => (
                    <div key={o.name} className="option">
                      <p>
                        {getTranslatedOption({ t, option: o })}: {o.value}
                      </p>
                    </div>
                  ))}
                </div>
                {i.listing.preOrder ? (
                  <p className="preorder">
                    {i.listing.available
                      ? `${t("preOrderNotice")} ${DateTime.fromISO(i.listing.available).toFormat("HH:mm")}`
                      : t("preOrderState")}
                  </p>
                ) : null}
                <div className="quantity">
                  {readOnly ? (
                    <p>{i.quantity}</p>
                  ) : (
                    <form onSubmit={handleSubmitQuantity}>
                      <div className="quantityEditor">
                        <button
                          disabled={isUpdating}
                          onClick={() => handleQuantityUpdate(i.quantity - 1, i.listing.id, true)}
                          type="button"
                        >
                          <i className="cg-icon-remove" />
                        </button>
                        <input type="hidden" name="listingId" value={i.listing.id} />
                        <input
                          type="number"
                          name="quantity"
                          required
                          min="0"
                          step="1"
                          onChange={e => handleQuantityUpdate(e.target.value, i.listing.id)}
                          max={i.listing.stock.quantity}
                          value={i.quantity || ""}
                        />
                        <button
                          disabled={isUpdating}
                          onClick={() => handleQuantityUpdate(i.quantity + 1, i.listing.id, true)}
                          type="button"
                        >
                          <i className="cg-icon-add" />
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="sale">
                <Price value={(preTaxes ? i.price.beforeTaxes : i.price.base) as number} />
              </div>
              <div className="total">
                <Price value={(preTaxes ? i.price.subtotalBeforeTaxes : i.price.subtotal) as number} />
              </div>
            </div>
          ))}
        </div>
        <div className="summary">
          <h2>{t("orderSummary")}</h2>
          <hr />
          <p className="justify">
            <span>{t("totalItems")}</span>
            <span>{checkout.totals.itemQuantity}</span>
          </p>
          <p className="justify">
            <span>{t("subtotal")}</span>
            <span>
              <Price value={preTaxes ? checkout.totals.subtotalBeforeTaxes : checkout.totals.subtotal} />
            </span>
          </p>
          {checkout.totals.taxes.map(t => (
            <p key={t.name} className="justify">
              <span>{t.name}</span>
              <Price value={t.amount as number} />
            </p>
          ))}
          <p className="justify">
            <span>{t("shipping")}</span>
            <span>
              {shopCollection ? (
                t("shopCollection")
              ) : (
                <>
                  {!checkout.shipping.method ? (
                    t("pending")
                  ) : (
                    <>
                      {shippingAddress.country} <Price value={checkout.shipping.price?.base || 0} />
                    </>
                  )}
                </>
              )}
            </span>
          </p>
          {checkout.totals.discount ? (
            <p className="justify">
              <span>{t("discount")}:</span>
              <Price value={checkout.totals.discount} />
            </p>
          ) : null}
          <hr />
          <h2 className="justify">
            <span>{t("total")}</span>
            <span>
              <Price value={checkout.totals.leftToPay as number} />
            </span>
          </h2>
          {placeBasket && !shopCollection ? (
            <p className="justify">
              <span />
              <span>+{t("shipping")}</span>
            </p>
          ) : null}
          {checkout.shipping && checkout.shipping.freeShippingThreshold ? (
            <div id="freeShippingThreshold">
              <p>
                {t("freeShippingThreshold")} <Price value={checkout.shipping.freeShippingThreshold} />
              </p>
            </div>
          ) : null}
          <a href={checkoutUrl}>
            <button id="proceedToCheckout">{t("proceedToCheckout")}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export const Login = ({
  login,
  t,
  setState,
  addNotification,
  config,
  noRegister
}: {
  login: ({ password, email }: { password: string; email: string }) => void;
  t: TFunction;
  setState: (state: string) => void;
  addNotification: AddNotification;
  config: Config;
  noRegister?: boolean;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      await login({ password, email });
      setState("basket");
    } catch (error: any) {
      console.error(error.toString());
      addNotification({ ok: 0, message: error.toString() });
      setError(error.message);
    }
  };

  return (
    <div id="login">
      <form onSubmit={handleLogin}>
        <label>
          {t("enterEmail")}
          <input
            type="email"
            name="email"
            placeholder={t("yourEmail") as string}
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </label>
        <label>
          {t("enterPwd")}
          <input
            type="password"
            name="password"
            placeholder={t("yourPassword") as string}
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </label>
        <div className="submit">
          <button type="submit" disabled={false} className="corporate">
            {t("login")}
          </button>
        </div>
        {error ? <p className="error">{error}</p> : null}
      </form>
      <div className="buttons">
        <button className="underline" type="button" onClick={() => setState("resetPassword")}>
          {t("lostYourPassword")}
        </button>
        {getConfigProperty(config, "information", "preventRegistration" || noRegister) ? null : (
          <button className="underline" type="button" onClick={() => setState("register")}>
            {t("createAccount")}
          </button>
        )}
      </div>
    </div>
  );
};

const Register = ({
  setState,
  addNotification,
  checkout,
  register,
  verify
}: {
  setState: (state: string) => void;
  addNotification: AddNotification;
  checkout: Checkout;
  register: (arg: RegisterMethod) => void;
  verify: (arg: VerifyMethod) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState<string | null>(null);
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);

  const handleRegister = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
      firstName: { value: string };
      lastName: { value: string };
    };
    const email = target.email.value.toLowerCase();
    const firstName = target.firstName.value;
    const lastName = target.lastName.value;
    const password = target.password.value;
    setEmail(email);
    try {
      await register({ email, firstName, lastName, password, withVerificationCode: true });
      setIsWaitingForConfirmation(true);
    } catch (e: any) {
      setError(e.toString());
      addNotification({ ok: 0, message: e.toString() });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitVerification = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      // @ts-expect-error no index
      await verify({ code: parseInt(e.target.code.value), checkoutId: checkout.id });
      setState("basket");
    } catch (e: any) {
      setError(e.message);
      addNotification({ ok: 0, message: e.toString() });
    } finally {
      setLoading(false);
    }
  };

  if (isWaitingForConfirmation)
    return (
      <div id="register">
        <form className="confirmation" onSubmit={handleSubmitVerification}>
          <label>
            {t("enterConfirmationCodeReceivedAt")}: <br /> {email}
            <input
              name="code"
              required
              type="number"
              autoComplete="off"
              min="1000"
              max="10000"
              placeholder={t("confirmationCode") + "..."}
            />
          </label>
          <div className="submit">
            <button type="submit" className="corporate">
              {t("submit")}
            </button>
          </div>
        </form>
      </div>
    );

  return (
    <div id="register">
      <form onSubmit={handleRegister} className="userCreate">
        <div className="createUserDetails">
          <label>
            {t("enterEmail")}
            <input required name="email" type="email" placeholder={t("yourEmail") as string} />
          </label>
        </div>
        <div className="twoColumns">
          <label>
            {t("firstName")}
            <input required name="firstName" type="string" placeholder={t("yourFirstName") as string} />
          </label>
          <label>
            {t("lastName")}
            <input required name="lastName" type="string" placeholder={t("yourLastName") as string} />
          </label>
        </div>
        <label>
          {t("enterPwd")}
          <input required name="password" placeholder={t("aSecurePassword") as string} type="password" />
        </label>
        <div className="submit">
          <button disabled={loading} type="submit" className="corporate">
            {loading ? "..." : t("submit")}
          </button>
        </div>
      </form>
      {error ? <p className="error">{error}</p> : null}
    </div>
  );
};

export const ResetPassword = ({ addNotification, setState }: { addNotification: AddNotification; setState: (state: string) => void }) => {
  const { t } = useTranslation();
  const [createPasswordToken, { loading: createPasswordTokenLoading }] = useMutation(POST_USER_CREATE_PASSWORD_TOKEN);
  const [passwordReset, { loading: passwordResetLoading }] = useMutation(POST_USER_PWD_RESET);
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);
  const [wasConfirmed, setWasConfirmed] = useState(false);
  const [getPasswordSession] = useMutation(POST_PASSWORD_USER_SESSION);
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordReset = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // @ts-expect-error no index
    const email = e.target.email.value;
    setEmail(email);
    try {
      await createPasswordToken({ variables: { email, withVerificationCode: true } });
      addNotification({ ok: 1, message: "Reset instructions were sent to your email address." });
      setIsWaitingForConfirmation(true);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const handleSubmitVerification = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      // @ts-expect-error no index
      const token = e.target.code.value.toUpperCase();
      await getPasswordSession({ variables: { token } });
      setToken(token);
      setWasConfirmed(true);
    } catch (e: any) {
      setErrorMessage(e.toString());
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const handleSubmitPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // @ts-expect-error no index
    const password = e.target.password.value;
    if (password.length < 6) return addNotification({ ok: 0, message: t("Password must be at least 6 characters long") });
    if (!token) return addNotification({ ok: 0, message: t("Invalid token") });

    try {
      const { data } = await passwordReset({ variables: { token, password: base64.encode(password) } });
      if (data?.userPasswordReset) {
        addNotification({ ok: 1, message: data.userPasswordReset });
        setState("login");
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  if (wasConfirmed)
    return (
      <div id="resetPassword">
        <form onSubmit={handleSubmitPassword}>
          <label>
            {t("yourEmailAddress")}
            <input type="email" readOnly name="email" required value={email}></input>
          </label>
          <label>
            {t("newPassword")}:
            <input type="password" placeholder={t("aSecurePassword") as string} autoComplete="off" name="password" required></input>
          </label>
          <div className="submit">
            <button type="submit" disabled={passwordResetLoading} className="corporate">
              {t("resetPassword")}
            </button>
          </div>
        </form>
      </div>
    );

  if (isWaitingForConfirmation)
    return (
      <div id="resetPassword">
        <form className="confirmation" onSubmit={handleSubmitVerification}>
          <label>
            {t("enterConfirmationCodeReceivedAt")}: {email}
            <input name="code" required type="text" min="1000" max="10000" autoComplete="off" placeholder={t("confirmationCode") + "..."} />
          </label>
          <div className="submit">
            <button type="submit" className="corporate">
              {t("submit")}
            </button>
          </div>
          {errorMessage ? <p>{errorMessage}</p> : null}
        </form>
      </div>
    );

  return (
    <div id="resetPassword">
      <form onSubmit={handlePasswordReset}>
        <label>
          {t("yourEmail")}
          <input type="email" placeholder={t("yourEmail") as string} name="email" required />
        </label>
        <div className="submit">
          <button disabled={createPasswordTokenLoading} type="submit" className="corporate">
            {createPasswordTokenLoading ? t("pleaseWait") : t("resetPassword")}
          </button>
        </div>
      </form>
    </div>
  );
};
export default Home;
