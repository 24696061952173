import { gql } from "../../__generated__/gql";

export const ITEM_FIELDS = gql(`
  fragment ItemFields on Item {
    _id
    id
    type
    path
    wanted
    uri
    isForbiddenForSale
    created
    seo {
      ...SeoFields
    }
    listings {
      _id
      id
      categories
      available
      preOrder
      secondHand
      stock {
        quantity
      }
      prices {
          beforeTaxes
          sale
          compare
      }
      onePerCustomer
      options {
          name
          value
      }
      comments
    }
    descriptions {
      main
      shop {
          text
          html
          short
      }
    }
    data {
      title
      identifiers {
        type
        value
      }
      images {
          uri
          alternative {
            uri
          }
      }
      assetLink
      weight
      genres
      styles
      manufacturer
      cat
      releaseDate
      tracklist {
        type_
        title
        artists {
          name
          id
          anv
        }
        uri
        duration
        position
      }
      formats {
        descriptions
        name
        qty
      }
      country
      artists {
        name
        anv
        id
        join
      }
      labels {
        name
        id
        catno
      }
      videos {
        uri
      }

      subtitle
      authors
      publisher
      publishedDate
      pageCount
      categories
      maturityRating
      language
    }
  }
`);

gql(`
  fragment PlaylistFields on Playlist {
    _id
    id
    title
    link
    entries {
      item {
        ...ItemFields
      }
      comments
    }
  }
`);

gql(`
  fragment CollectionFields on Collection {
    id
    title
    handle
    path
    viewType
    description {
      content
    }
    seo {
      ...SeoFields
    }
    sort {
      key
      order
    }
    banner {
      media {
        _id
        url
        alt
        formatArray {
          _id
          url
          name
        }
      }
      format
    }
  }
`);

export const GET_PLAYLIST = gql(`
  query playlist($id: Int!) {
    playlist(id: $id) {
      ...PlaylistFields
    }
  }
`);

export const GET_COLLECTION = gql(`
  query collection($handle: String, $id: Int, $page: Int, $limit: Int, $order: Int, $sort: String, $randomise: Boolean) {
    collection(handle: $handle, id: $id, page: $page, limit: $limit, order: $order, sort: $sort, randomise: $randomise) {
      collection {
        ...CollectionFields
      }
      page {
        pagination {
          limit
          pages
          page
          sort
          order
        }
        items {
          ...ItemFields
        }
      }
    }
  }
`);

export const GET_ITEM = gql(`
  query item($id: Float!) {
    item(id: $id) {
      ...ItemFields
    }
}`);

export const GET_ITEMS = gql(`
  query items($ids: [Float]!) {
    items(ids: $ids) {
      ...ItemFields
    }
  }
`);

export const GET_SEARCH_ITEM = gql(`
  query search($term: String!, $stocked: Boolean) {
    search(term: $term, stocked: $stocked) {
      items {
        ...ItemFields
      }
    }
}`);

export const GET_INVENTORY = gql(`
  query inventory($wantlist: Boolean, $to: String, $preorder: Boolean, $preorders: String, $from: String, $page: Int, $type: String, $manufacturers: [String], $itemTypes: [String!], $maxPrice: Int, $countries: [String], $years: [Int], $artists: [Float], $labels: [Float], $formats: [String], $categories: [String], $stock: String, $condition: String, $order: Int, $sort: String, $limit: Int, $styles: [String], $genres: [String], $mediums: [String]) {
    inventory(wantlist: $wantlist, to: $to, preorders: $preorders from: $from, preorder: $preorder, page: $page, type: $type, manufacturers: $manufacturers, itemTypes: $itemTypes, maxPrice: $maxPrice, countries: $countries, years: $years, artists: $artists, labels: $labels, formats: $formats, categories: $categories, stock: $stock, condition: $condition, order: $order, sort: $sort, styles: $styles, genres: $genres, limit: $limit, mediums: $mediums) {
      pagination {
        limit
        pages
        page
      }
      filters
      items {
        ...ItemFields
      }
    }
  }
`);

export const GET_ITEM_SUGGESTIONS = gql(`
  query suggestions($id: Float, $type: String, $genres: [String], $styles: [String], $categories: [String], $country:String, $limit: Int) {
    suggestions(id: $id, type: $type, genres: $genres, styles: $styles, categories: $categories, country: $country, limit: $limit) {
        ...ItemFields
    }
}`);

export const GET_ARTISTS = gql(`
  query artists{
    artists {
      id
      name
    }
}`);

export const GET_LABELS = gql(`
  query labels{
    labels {
      id
      name
      images {
        uri
      }
    }
}`);

export const POST_RELEASE_SNIPPET_ARCHIVE = gql(`
  mutation itemSnippetsArchive($id: Float!) {
    itemSnippetsArchive(id: $id)
}`);

export const POST_WANT = gql(`
  mutation addToWantlist($id: Float!) {
    want(id: $id) {
      ...ItemFields
    }
  }
`);
